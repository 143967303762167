:root {
  --bm-family: Calibre, Roboto, Helvetica;
  --bm-small-size: 12px;
  --bm-medium-size: 14px;
  --bm-large-size: 16px;
  --bm-border-color: rgba(0, 0, 0, .08);
  --bm-border-focus-color: #008efa;
  --bm-button-primary-color: #008efa;
  --bm-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.1);
}
/* :root {
  --bm-family: Inter,
    apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  --bm-small-size: 12px;
  --bm-medium-size: 14px;
  --bm-large-size: 16px;
  --bm-border-color: rgba(0, 0, 0, .08);
  --bm-border-focus-color: #008efa;
  --bm-button-primary-color: #008efa;
  --bm-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.1);
} */

body {
  font-family: var(--bm-family);
  font-size: var(--bm-medium-size);
  color: #2f3542;
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.bm-label {
  /* display: block; */
  height: 24px;
  line-height: 24px;
  position: absolute;
  background: #ffffff;
  border: 1px solid #2f3542;
  /* border-left-color: transparent; */
  border-left: 5px solid #3867d6;
  color: #2f3542;
  font: 14px / 21px 'Microsoft YaHei';
  z-index: 9;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0px 2px 0 8px;
  font-size: 1em;
  border-radius: 4px;
  font-family: var(--bm-family);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  user-select: none;
  -moz-user-select: none;
}

.bm-label-light {
  height: 28px;
  /* line-height: 24px; */
  position: absolute;
  background: #ffffff;
  /* border: 1px solid #2f3542; */
  border: 1px solid var(--bm-border-color);
  /* border-left-color: transparent; */
  border-left: 5px solid #3867d6;
  color: #2f3542;
  /* font: 14px / 21px 'Microsoft YaHei'; */
  z-index: 9;
  transition:
    opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 0px 0px 0 8px;
  font-size: 1em;
  border-radius: 4px;
  font-family: var(--bm-family);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  user-select: none;
  -moz-user-select: none;
}

.context-menu {
  display: none;
  z-index: 9999;
  padding: 5px;
  position: absolute;
  background-color: #fff;
  /* border: 1px solid #dedede; */
  border-radius: 4px;
  animation: popup__1NaWC .3s cubic-bezier(.34, 1.56, .64, 1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, .05), 0px 4px 10px rgba(0, 0, 0, .1);
  z-index: 100;
  font-family: var(--bm-family);
}

.lightTheme .groupMenuPanel__659Pm:after {
  background: hsla(240, 3%, 86%, .4);
}

.groupMenuPanel__659Pm:after {
  background: rgba(35, 35, 40, .4);
  bottom: -5px;
  content: " ";
  display: block;
  height: 1px;
  left: 50%;
  margin-top: 8px;
  position: absolute;
  transform: translateX(-50%);
  width: calc(100% - 24px);
}

@keyframes popup__1NaWC {
  0% {
    opacity: 0;
    transform: scale(.985);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 10px;
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 16px;
  justify-content: space-between;
  line-height: 16px;
  padding: 7px 6px 7px 18px;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: 144px;
  font-size: var(--bm-medium-size);

}

.context-menu li:hover {
  /* background-color: #008efa !important;
  color: #fff !important; */
  background-color: #e3effb !important;
  color: #12467b !important;
}

.sub-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
}

/* .MuiList-root {
  padding: 5px;
} */

/* .MuiTypography-root {
  font-size: 14px !important;
} */

.MuiSvgIcon-root {
  font-size: 18px;
}

.MuiMenu-paper {
  padding: 0px 5px;
  border: none !important;
  box-shadow: var(--bm-box-shadow) !important;
  font-family: var(--bm-family) !important;
}

.MuiDialogTitle-root {
  font-size: 16px !important;
}

/* .MuiDialogContentText-root {} */


.MuiMenuItem-root {
  font-size: 12px !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

/* .MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.00938em;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
} */

.MuiTypography-body1 {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.00938em;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
}

.MuiMenuItem-root:hover {
  /* background-color: #008efa !important;
  color: #fff !important; */
  background-color: #e3effb !important;
  color: #12467b !important;
}

.ant-color-picker {
  z-index: 99999;
}

.ant-tooltip .ant-tooltip-inner {
  /* opacity: 0.6; */
  font-size: 12px;
  min-width: 24px;
  min-height: 24px;
  padding: 2px 3px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 4px !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.ant-tooltip {
  --antd-arrow-background-color: rgba(0, 0, 0, 0.55);
}

.ant-dropdown-menu-item:hover {
  background-color: #e3effb !important;
  color: #12467b !important;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.channel-label-btn {
  /* color: #57606f */
  cursor: pointer;
}

.channel-label-btn:hover {
  color: #000;
  /* background-color: #f1f2f6; */
}

.channel-label-menu-divider::after {
  background: hsla(240, 3%, 86%, .4);
  bottom: -5px;
  content: " ";
  display: block;
  height: 1px;
  left: 50%;
  margin-top: 8px;
  margin-bottom: 8px;
  width: calc(100% - 24px);
}

/* ---------------------------------------------------------------- */

.dialog-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  /* 让对话框内容居中 */
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background-color: #f7f8fa;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 300px;
}

.dialog-title {
  font-size: 16px;
  font-weight: 600;
}

.edit-input {
  width: 280px;
  /* padding: 8px; */
  padding: 0 8px;
  margin-bottom: 16px;
  max-height: 32px;
  min-height: 32px;
  outline: 1px solid var(--bm-border-color) !important;
  border: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.edit-input:focus {
  outline: 1px solid var(--bm-border-focus-color) !important;
  box-shadow: none;
}


.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-button,
.cancel-button {
  padding: 7px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button {
  background-color: var(--bm-button-primary-color);
  color: white;
}

.cancel-button {
  background-color: #eeeff0;
  color: #202020;
}

/* ---------------------------------------------------------------- */



.fadeOutHide {
  animation-name: fadeOut;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.fadeInShow {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}


.bm-loading {
  /* display: block; */
  opacity: 0;
  position: absolute;
  background: #ffffff;
  color: #57606f;
  width: 300px;
  z-index: 999999;
  padding: 0px;
  font-size: 0.9em;
  user-select: none;
  -moz-user-select: none;
  text-align: center;
  background: url('../img/loading-bar-blue.svg') no-repeat;
  background-position: 0% 50%;
  background-size: 50px 50px;
}

.bm-loading span {
  vertical-align: middle;
  margin-top: 20px;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
}

.bm-loading2 {
  display: block;
  position: absolute;
  background: #ffffff;
  color: #57606f;
  z-index: 999999;
  padding: 2px 8px;
  font-size: 0.9em;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  user-select: none;
  -moz-user-select: none;
  text-align: center;

  background: url('../img/loading-bar-blue.svg') no-repeat;
  background-position: 50% 50%;
  background-size: 50px 50px;
}

.bm-loading-error {
  display: block;
  position: absolute;
  background: #ffffff;
  color: #ff6b81;
  z-index: 999999;
  padding: 2px 8px;
  font-size: 0.9em;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  user-select: none;
  -moz-user-select: none;
  text-align: center;
  background: transparent;
}

/* drop */
.bm-dragover {
  box-shadow: 0 0 10px 3px lightblue;
}
